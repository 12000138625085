import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { BaseApiService } from '../../utils/BaseApiService';
import { Dropdown } from 'primereact/dropdown';
import { convertDateFormat, toReadableDate, toReadableTime } from '../../utils/Utils';
import { UserSessionUtils } from '../../utils/UserSessionUtils';
import { MAXIMUM_RECORDS_PER_PAGE } from '../../constants/Constants';
import { Paginator } from 'primereact/paginator';
import { formatNumberWithCommas } from '../../utils/Utils';
import { Calendar } from 'primereact/calendar';
import StatusSummary from '../../components/StatusSummary';
import SaleSummaryDialog from '../../components/dialogs/SaleSummary';
import RenderCurrency from '../../components/RenderCurrency';
import { RecieptPrintDialog } from '../RecieptPrintout';
export default function AttendantSales() {
    const toast = useRef(null);
    const dt = useRef(null);
    const [productDialog, setProductDialog] = useState(false);
    const [showPrintDialog, setShowPrintDialog] = useState(false);
    const [shopSales, setShopSales] = useState([]);
    const [selectedSale, setSelectedSale] = useState(null); //the table row
    const [limit, setLimit] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [first, setFirst] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [totalItems, setTotalItems] = useState(0);
    const [income, setIncome] = useState(0);
    const [salesValue, setSalesValue] = useState(0);
    const [capital, setCapital] = useState(0);
    const [dates, setDates] = useState(null);
    let offset = 0;
    const calendarRef = useRef(null);
    const fetchSales = async () => {
        calendarRef.current.hide();
        setIsLoading(true);
        let searchParameters = {
            searchTerm: searchTerm,
            offset: offset,
            limit: MAXIMUM_RECORDS_PER_PAGE,
            ...(dates && { startDate: convertDateFormat(dates[0]) }),
            ...(dates && !dates[1] && dates[0] && { endDate: convertDateFormat(dates[0], true) }),
            ...(dates && dates[1] && { endDate: convertDateFormat(dates[1], true) })
        };
        if (UserSessionUtils.getShopAttendant()) {
            searchParameters.shopId = UserSessionUtils.getShopId();
        }
        setTotalItems(0);
        await new BaseApiService('/shop-sales')
            .getRequestWithJsonResponse(searchParameters)
            .then((response) => {
                setShopSales(response.records);
                setTotalRecords(response.totalItems);
                setIsLoading(false);
                let qty = [];
                let cartIncome = [];
                let cartCapital = [];
                response.records.forEach((item) => {
                    let items = item?.lineItems.reduce((a, item) => a + item?.quantity, 0);
                    let cartProfit = item?.lineItems.reduce((a, i) => a + i.totalProfit, 0);
                    let cap = item?.lineItems.reduce((a, i) => a + i.totalPurchaseCost, 0); // cart capital
                    cartIncome.push(Math.round(cartProfit));
                    qty.push(items);
                    cartCapital.push(cap);
                });
                setCapital(cartCapital.reduce((a, b) => a + b, 0));
                setTotalItems(qty.reduce((a, b) => a + b, 0));
                setIncome(cartIncome.reduce((a, b) => a + b, 0));
                setSalesValue(response.records.reduce((a, b) => a + b.totalCost, 0));
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: error?.message, life: 3000 });
                setIsLoading(false);
            });
    };
    const onPageChange = (e) => {
        offset = e.page * MAXIMUM_RECORDS_PER_PAGE;
        setFirst(e.first);
        setLimit(MAXIMUM_RECORDS_PER_PAGE);
        fetchSales();
    };
    useEffect(() => {
        fetchSales();
    }, []);
    const header = (
        <div className="flex flex-column md:flex-row md:align-items-center">
            <h5 className="m-0 line relative">Shop Sales</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left  ml-5">
                <div className="p-inputgroup flex-1">
                    <Calendar ref={calendarRef} className="w-full" selectionMode="range" readOnlyInput hideOnRangeSelection maxDate={new Date()} value={dates} onChange={(e) => setDates(e.value)} placeholder="Date" dateFormat="dd-M-yy" />
                    <Button icon={!dates ? 'pi pi-calendar' : 'pi pi-times-circle'} className="primary-btn" onClick={() => setDates(null)} />
                </div>
            </span>
            <span className="block mt-2 md:mt-0 p-input-icon-left ml-5">
                <div className="p-inputgroup flex-1">
                    <InputText placeholder="Search" onInput={(e) => setSearchTerm(e.target.value)} />
                    <Button icon="pi pi-search" className="primary-btn" onClick={fetchSales} />
                </div>
            </span>
        </div>
    );
    const template2 = {
        layout: 'CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 }
            ];
            return (
                <React.Fragment>
                    <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}></span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                    <span className="mr-auto"></span>
                </React.Fragment>
            );
        },
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: 'auto', textAlign: 'center' }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} shop sales
                </span>
            );
        }
    };
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex justify-content-center md:align-items-center ">
                <Button
                    icon="pi pi-eye"
                    className="p-button-rounded p-button-text p-button-success "
                    onClick={() => {
                        setSelectedSale(rowData);
                        setProductDialog(true);
                    }}
                />
                <Button
                    icon="pi pi-print"
                    className="p-button-rounded p-button-text p-button-info "
                    onClick={() => {
                        setSelectedSale(rowData);
                        setShowPrintDialog(true);
                    }}
                />
            </div>
        );
    };
    const amountBodyTemplate = (rowData) => {
        return (
            <>
                <RenderCurrency value={rowData.totalCost} currencySymbol={rowData?.currency} />
            </>
        );
    };
    const recievedBodyTemplate = (rowData) => {
        return (
            <>
                <RenderCurrency value={rowData.amountPaid} currencySymbol={rowData?.currency} />
            </>
        );
    };
    const balanceBodyTemplate = (rowData) => {
        return (
            <>
                <RenderCurrency value={rowData.balanceGivenOut} currencySymbol={rowData?.currency} />
            </>
        );
    };
    const createdByFullNameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Created by</span>
                {rowData.createdByFullName} <br />
            </>
        );
    };
    const dateCreatedBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Sold on</span>
                {toReadableDate(rowData.soldOnDate)} <br />
                {toReadableTime(rowData.dateCreated)}
            </>
        );
    };
    const itemsBodyTemplete = (rowData) => {
        let items = rowData?.lineItems.reduce((a, b) => a + b.quantity, 0);
        return (
            <>
                <span className="p-column-title">Items</span>
                {formatNumberWithCommas(items)}
            </>
        );
    };
    const Income = (rowData) => {
        const cartProfit = rowData?.lineItems.reduce((a, i) => a + i.totalProfit, 0);
        return (
            <>
                <RenderCurrency value={Math.round(cartProfit)} currencySymbol={rowData?.currency} />
            </>
        );
    };
    const nameBodyTemplete = (rowData) => {
        const { name } = rowData;
        let newName = name.split(',').filter((i) => i.length > 0);
        newName = newName.join(',');
        if (newName.length > 22) {
            newName = newName.slice(0, 30).concat('...');
        }
        return (
            <>
                <span className="p-column-title">Transaction</span>
                {newName} <br />
            </>
        );
    };
    const hideDialog = () => {
        setSelectedSale(null);
        setProductDialog(false);
    };
    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="py-2 -mt-3">
                    <div className="grid">
                        <StatusSummary iconName="pi pi-shopping-cart " title="Txns" value={formatNumberWithCommas(totalRecords)} />
                        <StatusSummary title="Sales" value={formatNumberWithCommas(salesValue)} iconName="bx bx-receipt" />
                        <StatusSummary title="Capital" value={formatNumberWithCommas(Math.round(capital))} iconName="pi pi-money-bill" />
                        <StatusSummary title="Income" value={formatNumberWithCommas(income)} iconName="bx bx-wallet" />
                    </div>
                </div>
                <div className="py-2 -mt-1">
                    <div className="grid">
                        <div className="col-12 md:col-12 lg:col-12">
                            <div className="card">
                                <Toast ref={toast} />
                                {header}
                                <DataTable loading={isLoading} ref={dt} value={shopSales} dataKey="id" rows={10} className="datatable-responsive" emptyMessage="No shop sales found." size="small" resizableColumns>
                                    <Column field="shopName" header="Transaction " body={nameBodyTemplete} style={{ width: '10%' }} />
                                    <Column header="Qty" body={itemsBodyTemplete} className="text-center" headerStyle={{ textAlign: 'center' }} />
                                    <Column header="Recieved" body={recievedBodyTemplate} className="text-right" headerStyle={{ textAlign: 'right' }} />
                                    <Column header="Amount" body={amountBodyTemplate} className="text-right" headerStyle={{ textAlign: 'right' }} />
                                    <Column header="Balance" body={balanceBodyTemplate} className="text-right" headerStyle={{ textAlign: 'right' }} />
                                    <Column header="Income" body={Income} className="text-right" headerStyle={{ textAlign: 'right' }} />
                                    <Column field="createdByFullName" body={createdByFullNameBodyTemplate} header="Created by" style={{ paddingLeft: '5rem' }} />
                                    <Column header="Sold on" body={dateCreatedBodyTemplate} style={{ width: '15%' }} />
                                    <Column body={actionBodyTemplate} header="Actions" headerStyle={{ textAlign: 'center' }} style={{ width: '10%' }} />
                                </DataTable>
                                <Paginator template={template2} first={first} rows={MAXIMUM_RECORDS_PER_PAGE} totalRecords={totalRecords} alwaysShow={false} onPageChange={onPageChange} />
                            </div>
                        </div>
                    </div>
                </div>
                <SaleSummaryDialog visible={productDialog} hideDialog={hideDialog} selectedSale={selectedSale} />
                <RecieptPrintDialog saleData={selectedSale} toggleFn={setShowPrintDialog} visible={showPrintDialog} reloadFn={setShowPrintDialog} />
            </div>
        </div>
    );
}
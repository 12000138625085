import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { BaseApiService } from '../../utils/BaseApiService';
import { Dropdown } from 'primereact/dropdown';
import { convertDateFormat, toReadableDate, toReadableTime } from '../../utils/Utils';
import { UserSessionUtils } from '../../utils/UserSessionUtils';
import { MAXIMUM_RECORDS_PER_PAGE } from '../../constants/Constants';
import { Paginator } from 'primereact/paginator';
import { formatNumberWithCommas } from '../../utils/Utils';
import { RecieptPrintDialog } from '../RecieptPrintout';
import { Calendar } from 'primereact/calendar';
import StorageParams from '../../constants/StorageParams';
import SaleSummaryDialog from '../../components/dialogs/SaleSummary';
import PaymentModeForm from '../../components/dialogs/PaymentModeForm';
import HeldModeForm from '../../components/HeldSale';
import { generatePath, useNavigate } from 'react-router-dom';
import { CREDIT_SALES_ROUTE_PATH } from '../../utils/NavigationRoutes';
import { REPORTS_ROUTE_PATH } from '../../utils/NavigationRoutes';

export default function ShopSales() {
    const toast = useRef(null);
    const navigate = useNavigate();

    const [products, setProducts] = useState([]);
    const [productDialog, setProductDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [submitted2, setSubmitted2] = useState(false);
    const [shopSales, setShopSales] = useState([]);
    const [selectedShop, setSelectedShop] = useState(null);
    const [selectedHeldTrans, setSelectedHeldTrans] = useState(null);
    const [heldTrans, setHeldTrans] = useState(JSON.parse(localStorage.getItem(StorageParams.HELD_TRANSACTIONS)) || []);
    const [shops, setShops] = useState([]);

    const [receivedAmount, setReceivedAmount] = useState(0);
    const [quantity, setQuantity] = useState(null);
    const [unitCost, setUnitCost] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedSale, setSelectedSale] = useState(null); //the table row
    const [saleUnits, setSaleUnits] = useState([]);
    const [selectedSaleUnit, setSelectedSaleUnit] = useState(null);
    const [cartItems, setCartItems] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [errors, setErrors] = useState(null);
    const [selectedCurrency, setSelectedCurrency] = useState(null);

    const [limit, setLimit] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [first, setFirst] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [enterQty, setEnterQty] = useState(false);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);

    const [showPaymentDialog, setShowPaymentDialog] = useState(false);
    const [showHeldDialog, setShowHeldDialog] = useState(false);

    const [showPrintDialog, setShowPrintDialog] = useState(false);

    const [dates, setDates] = useState(null);

    let offset = 0;
    const isShopAttendant = UserSessionUtils.getShopAttendant();

    const fetchProducts = async () => {
        let searchParameters = {
            offset: 0,
            limit: 20,
            ...(searchTerm.trim() !== '' && { searchTerm: searchTerm }),
            ...(selectedShop && { shopId: selectedShop?.id }),
            ...(isShopAttendant && { shopId: UserSessionUtils.getShopId() })
        };

        if (isShopAttendant || selectedShop) {
            new BaseApiService('/shop-products')
                .getRequestWithJsonResponse(searchParameters)
                .then(async (response) => {
                    setProducts(
                        response.records?.filter((item) => {
                            const remainingStock = item?.performanceSummary?.totalQuantityStocked - item?.performanceSummary?.totalQuantitySold;

                            return remainingStock >= 1;
                        })
                    );
                    setDisabled(false);
                })
                .catch((error) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: error?.message, life: 3000 });
                    setDisabled(false);
                });
        }
    };

    const saveSale = () => {
        setLoading(true);
        setSubmitted(true);
        let isValidSubmision;
        if (isShopAttendant) {
            isValidSubmision = receivedAmount >= totalAmount && cartItems.length > 0;
        } else {
            const isvalidAmount = receivedAmount >= totalAmount && cartItems.length > 0;
            isValidSubmision = isvalidAmount && selectedShop;
        }

        !isValidSubmision && setLoading(false);

        if (isValidSubmision) {
            setShowPaymentDialog(true);
            setLoading(false);
        }
    };

    const saveHeldTrans = () => {
        setLoading(true);
        if (cartItems.length > 0) {
            setShowHeldDialog(true);
            setLoading(false);
            return;
        }
        setLoading(false);
    };

    const fetchSales = async () => {
        setIsLoading(true);

        let searchParameters = {
            offset: offset,
            limit: MAXIMUM_RECORDS_PER_PAGE,
            ...(dates && { startDate: convertDateFormat(dates[0]) }),
            ...(dates && !dates[1] && dates[0] && { endDate: convertDateFormat(dates[0], true) }),
            ...(dates && dates[1] && { endDate: convertDateFormat(dates[1], true) })
        };
        if (UserSessionUtils.getShopOwnerId()) {
            searchParameters.shopOwnerId = UserSessionUtils.getShopOwnerId();
        }

        if (UserSessionUtils.getShopAttendant()) {
            searchParameters.shopId = UserSessionUtils.getShopId();
        }
        new BaseApiService('/shop-sales')
            .getRequestWithJsonResponse(searchParameters)
            .then((response) => {
                setShopSales(response.records);
                setTotalRecords(response.totalItems);
                setIsLoading(false);
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: error?.message, life: 3000 });
                setIsLoading(false);
            });
    };

    const fetchShops = async () => {
        let searchParameters = { offset: 0, limit: 0 };

        if (UserSessionUtils.getShopOwnerId()) {
            searchParameters.shopOwnerId = UserSessionUtils.getShopOwnerId();
        }

        if (!isShopAttendant) {
            new BaseApiService('/shops')
                .getRequestWithJsonResponse(searchParameters)
                .then(async (response) => {
                    setShops(response.records);
                    if (response.records.length === 1) {
                        setSelectedShop(response.records[0]);
                    }
                })
                .catch((error) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: error?.message, life: 3000 });
                });
        }
    };

    const handleSaleUnitChange = (unit) => {
        setSelectedSaleUnit(unit);
        setUnitCost(unit?.unitPrice);
    };

    const handleProductChange = (e) => {
        const { multipleSaleUnits, saleUnitName, salesPrice } = e.value;

        let defUnit = { productSaleUnitName: saleUnitName, unitPrice: salesPrice };
        if (multipleSaleUnits) {
            setSaleUnits([defUnit, ...multipleSaleUnits]);
        } else {
            setSaleUnits([{ ...defUnit }]);
            setSelectedSaleUnit(defUnit);
            setUnitCost(salesPrice);
        }
        setSubmitted(false);
        setSelectedItem(e.value);

        setSelectedCurrency(e?.value?.currency);

        setEnterQty(true);
    };

    const handleHeldTransactionChange = (e) => {
        setSelectedHeldTrans(e.value);
        setTotalAmount(e.value.sold);
        setReceivedAmount(e.value.received);
        setCartItems(e.value.cart);
    };

    const handleQty = () => {
        setSubmitted2(true);
        let itemUnitCost = unitCost;
        let cost = itemUnitCost * quantity;
        const isWhole = selectedSaleUnit?.id === undefined;

        const productIndex = cartItems.findIndex((item) => {
            if (isWhole) {
                return item?.productName === selectedItem?.productName;
            } else {
                return item?.saleUnitId === selectedSaleUnit?.id;
            }
        });

        const isValidCost = unitCost >= selectedSaleUnit?.unitPrice;

        if (isValidCost === false) {
            setErrors((prev) => {
                return { ...prev, costError: `Unit cost should be greater than ${selectedItem?.salesPrice}` };
            });
        }

        if (isValidCost && Number(quantity) > 0) {
            if (productIndex !== -1) {
                //if it already exists, update quantity and total cost
                let prevQty = cartItems[productIndex].quantity;
                let prevTotalCost = cartItems[productIndex].cost;

                cartItems[productIndex].quantity = Number(quantity) + prevQty;
                cartItems[productIndex].cost = prevTotalCost + cost;
            } else {
                let unitName = selectedSaleUnit?.id !== undefined ? ' - ' + selectedSaleUnit?.productSaleUnitName : '';

                cartItems.push({
                    id: selectedItem.id,
                    productName: selectedItem?.productName + unitName,
                    shopProductId: selectedItem.id,
                    quantity: quantity,
                    cost: cost,
                    unitCost: itemUnitCost,
                    barcode: selectedItem.barcode,
                    currency: selectedItem.currency,
                    saleUnitId: selectedSaleUnit?.id || null
                });
            }
            setSubmitted2(false);
            setTotalAmount(totalAmount + cost);
            setQuantity(null);
            setUnitCost(null);
            setSelectedSaleUnit(null);
            hideQtyDialog();
        }
    };

    const removeSelection = (data) => {
        const upDatedList = cartItems.filter((item) => data.productName !== item.productName);
        setTotalAmount(totalAmount - data?.cost);
        setCartItems(upDatedList);
    };

    const onPageChange = (e) => {
        offset = e.page * MAXIMUM_RECORDS_PER_PAGE;
        setFirst(e.first);
        setLimit(MAXIMUM_RECORDS_PER_PAGE);

        fetchSales();
    };
    const clearEverything = () => {
        setReceivedAmount(0);
        setSearchTerm('');
        setSelectedItem(null);
        setQuantity(null);
        setSelectedSale(null);
        setTotalAmount(0);
        setCartItems([]);
        setSubmitted(false);
        setSelectedSale(null);
        setSelectedHeldTrans(null);
        setSelectedSaleUnit(null);
        setSelectedCurrency(null);
    };
    useEffect(() => {
        fetchProducts();
    }, [searchTerm, selectedShop]);

    useEffect(() => {
        fetchSales();
        fetchShops();
    }, []);

    useEffect(() => {
        clearEverything();
    }, [selectedShop]);

    const barcode = (rowData) => {
        return (
            <>
                <span className="pad">{rowData.barcode}</span>
            </>
        );
    };

    const hideDialog = () => {
        setSelectedSale(null);
        setProductDialog(false);
    };

    const hidePaymentDialog = () => {
        setShowPaymentDialog(false);
    };

    const hideHeldDialog = () => {
        setShowHeldDialog(false);
    };

    const hideQtyDialog = () => {
        setEnterQty(false);
        setSelectedItem(null);
        setQuantity(null);
        setSubmitted2(false);
        setErrors(null);
        setSelectedSaleUnit(null);
        setSaleUnits([]);
    };

    const handleDirect = () => {
        navigate(generatePath(REPORTS_ROUTE_PATH, ''));
    };

    const QtyDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="outline-btn" onClick={hideQtyDialog} />
            <Button label="Confirm" icon="pi pi-check" className="primary-btn" onClick={handleQty} />
        </>
    );

    const selectionsActionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex justify-content-end align-items-end">
                <Button icon="pi pi-trash" className="p-button-rounded p-button-text p-button-danger " onClick={() => removeSelection(rowData)} />
            </div>
        );
    };

    return (
        <div className="grid crud-demo h-full">
            <div className="col-12 h-full">
                <div className="py-2 -mt-3 h-full">
                    <div className="grid h-full">
                        {/* {LandScape} */}
                        <div className="col-12 md:col-12 lg:col-4 w-12 lg:block md:hidden xl:block hidden">
                            <div className="h-full">
                                <div className=" mb-2 card flex">
                                    <div className="flex w-full">
                                        {!isShopAttendant && (
                                            <div className="w-2 mr-3">
                                                <label htmlFor="product">Select Shop</label>
                                                <Dropdown
                                                    style={{ width: '100%', height: '3rem' }}
                                                    value={selectedShop}
                                                    options={shops}
                                                    optionLabel={(product) => `${product.name} `}
                                                    onChange={(e) => {
                                                        setSelectedShop(e.value);
                                                    }}
                                                    placeholder="Select shop"
                                                    filter
                                                    className={classNames({ 'p-invalid': submitted && !selectedShop })}
                                                    filterInputAutoFocus
                                                />
                                            </div>
                                        )}

                                        <div className="w-4">
                                            <label htmlFor="product">Select Held Transaction</label>

                                            <Dropdown
                                                disabled={disabled}
                                                style={{ width: '100%', height: '3rem' }}
                                                value={selectedHeldTrans}
                                                options={UserSessionUtils.getShopOwner ? heldTrans.filter((transaction) => transaction.shopId === selectedShop?.id) : heldTrans}
                                                optionLabel={(trans) => `${trans.clientName} `}
                                                onChange={handleHeldTransactionChange}
                                                placeholder="Select transaction"
                                                filter
                                                filterInputAutoFocus
                                                onFilter={(e) => setSearchTerm(e.filter)}
                                                className={classNames({ 'p-invalid': submitted && !cartItems.length > 0 })}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-5 flex flex-column justify-content-end">
                                        <label htmlFor="product">Select Product</label>

                                        <Dropdown
                                            disabled={disabled}
                                            style={{ width: '100%', height: '3rem' }}
                                            value={selectedItem}
                                            options={products}
                                            optionLabel={(product) => `${product.productName} `}
                                            onChange={handleProductChange}
                                            placeholder="Select item"
                                            filter
                                            filterInputAutoFocus
                                            onFilter={(e) => setSearchTerm(e.filter)}
                                            className={classNames({ 'p-invalid': submitted && !cartItems.length > 0 })}
                                        />
                                    </div>
                                    <Button className=" font-semibold primary-btn h-3rem mt-4 ml-2 pr-4 pl-2" label="Sales" onClick={handleDirect} />
                                </div>

                                <div className="card mb-0">
                                    <div className="flex">
                                        <DataTable value={cartItems} className="datatable-responsive  w-full" size="small" emptyMessage=" " style={{ minHeight: '450px' }} scrollHeight="450px" scrollable>
                                            <Column field="productName" header="Item" style={{ minWidth: '8%' }} className="text-left" />
                                            <Column field="BarCode" header="BarCode" style={{ minWidth: '8%' }} body={barcode} className="text-center " />
                                            <Column field="unitCost" header="Price" body={(data) => formatNumberWithCommas(data?.unitCost)} className="text-center" style={{ maxWidth: '13%' }} />
                                            <Column field="quantity" header="Qty" className="text-center" style={{ maxWidth: '8%' }} />
                                            <Column field="cost" header="Amount" body={(data) => formatNumberWithCommas(data?.cost)} headerStyle={{ textAlign: 'right' }} className="text-right" style={{ maxWidth: '8%' }} />
                                            <Column style={{ maxWidth: '4%' }} field="action" header="Action" body={selectionsActionBodyTemplate} className="text-left" />
                                        </DataTable>
                                    </div>
                                    <div className="px-2 flex justify-content-between">
                                        <div className=" w-3 text-lg mt-7 flex justify-content-between">
                                            <div className="flex flex-column">
                                                <label>Client name</label>
                                                <label className="font-semibold">
                                                    {selectedHeldTrans == null && '--'} {selectedHeldTrans && selectedHeldTrans.clientName}
                                                </label>
                                            </div>
                                            <div className="flex flex-column">
                                                <label>Phone number</label>
                                                <label className="font-semibold">
                                                    {selectedHeldTrans == null && '--'} {selectedHeldTrans && selectedHeldTrans.clientNumber}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="w-4 pl-4">
                                            <div className="formgrid grid h-auto mt-2 border-1 border-round border-400 align-items-center">
                                                <div className="col">
                                                    <label htmlFor="qnty">Received amount</label>
                                                </div>

                                                <div className=" col">
                                                    <InputNumber value={receivedAmount} inputClassName=" border-none text-right font-semibold" onValueChange={(e) => setReceivedAmount(e.value)} required />
                                                </div>
                                            </div>
                                            <div className="flex justify-content-between mt-2">
                                                <span className="">Sold</span>
                                                <span className="text-sm font-normal px-2">
                                                    {selectedCurrency} <span className="text-lg font-semibold ">{formatNumberWithCommas(totalAmount)}</span>
                                                </span>
                                            </div>
                                            <div className="flex justify-content-between mt-2">
                                                <span>Balance</span>
                                                <span className="text-sm font-normal px-2">
                                                    {selectedCurrency} <span className="text-lg font-semibold ">{formatNumberWithCommas(receivedAmount - totalAmount)}</span>
                                                </span>
                                            </div>
                                            <div className=""> {submitted && totalAmount > receivedAmount && <small className="p-error">{`Amount should be greater than   ${selectedCurrency + totalAmount}`}</small>}</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex justify-content-end bg-white pt-3 border-round-xs border-1 border-gray-100">
                                    <div className="w-4 pr-4">
                                        <Button className="w-full font-semibold primary-btn h-4rem mb-3" label="Confirm Purchase" onClick={saveSale} loading={loading} />
                                        <div className="w-full flex justify-content-between mb-3">
                                            <Button className=" w-6 font-semibold outline-btn h-3rem mr-3" label="Clear" onClick={clearEverything} loading={loading} />
                                            <Button badge={heldTrans.length} className="w-6 font-semibold outline-btn h-3rem" label="Hold" onClick={saveHeldTrans} loading={loading} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Mobile Phone */}
                        <div className="col-12 md:col-12 lg:col-4 block lg:hidden xl:hidden md:block">
                            <div className="">
                                <div className=" mb-2 card">
                                    {!isShopAttendant && (
                                        <>
                                            <label htmlFor="product">Select Shop</label>
                                            <Dropdown
                                                style={{ width: '100%' }}
                                                value={selectedShop}
                                                options={shops}
                                                optionLabel={(product) => `${product.name} `}
                                                onChange={(e) => {
                                                    setSelectedShop(e.value);
                                                }}
                                                placeholder="Select shop"
                                                filter
                                                className={classNames({ 'p-invalid': submitted && !selectedShop })}
                                                filterInputAutoFocus
                                            />
                                        </>
                                    )}

                                    <div className="mt-2 ">
                                        <label htmlFor="product">Select Product</label>

                                        <Dropdown
                                            disabled={disabled}
                                            style={{ width: '100%' }}
                                            value={selectedItem}
                                            options={products}
                                            optionLabel={(product) => `${product.productName} `}
                                            onChange={handleProductChange}
                                            placeholder="Select item"
                                            filter
                                            filterInputAutoFocus
                                            onFilter={(e) => setSearchTerm(e.filter)}
                                            className={classNames({ 'p-invalid': submitted && !cartItems.length > 0 })}
                                        />
                                    </div>
                                </div>

                                <div className="mb-3 card">
                                    <DataTable value={cartItems} className="datatable-responsive col-12" size="small" emptyMessage=" " style={{ minHeight: '200px' }}>
                                        <Column field="productName" header="Item" className="text-left" style={{ width: '55%' }} />
                                        <Column field="unitCost" header="Price" body={(data) => formatNumberWithCommas(data?.unitCost)} className="text-center" />
                                        <Column field="quantity" header="Qty" className="text-center" />
                                        <Column field="cost" header="Amount" body={(data) => formatNumberWithCommas(data?.cost)} headerStyle={{ textAlign: 'right' }} className="text-right" />
                                        <Column body={selectionsActionBodyTemplate} className="text-center" />
                                    </DataTable>
                                    <div className="px-2 mt-2">
                                        <div className="formgrid grid h-auto mt-2 border-1 border-round border-400 align-items-center">
                                            <div className="col">
                                                <label htmlFor="qnty">Received amount</label>
                                            </div>

                                            <div className=" col">
                                                <InputNumber value={receivedAmount} inputClassName=" border-none text-right font-semibold" onValueChange={(e) => setReceivedAmount(e.value)} required />
                                            </div>
                                        </div>
                                        <div className="flex justify-content-between mt-2">
                                            <span className="">Sold</span>
                                            <span className="text-sm font-normal px-2">
                                                {selectedCurrency} <span className="text-lg font-semibold ">{formatNumberWithCommas(totalAmount)}</span>
                                            </span>
                                        </div>
                                        <div className="flex justify-content-between mt-2">
                                            <span>Balance</span>
                                            <span className="text-sm font-normal px-2">
                                                {selectedCurrency} <span className="text-lg font-semibold ">{formatNumberWithCommas(receivedAmount - totalAmount)}</span>
                                            </span>
                                        </div>
                                        <div className=""> {submitted && totalAmount > receivedAmount && <small className="p-error">{`Amount should be greater than   ${selectedCurrency + totalAmount}`}</small>}</div>
                                    </div>
                                </div>

                                <div className=" flex justify-content-between gap-3">
                                    <div className={`bg-white p-2 flex flex-column align-items-center justify-content-center border-round w-full`}>
                                        <i className={`pi pi-credit-card text-4xl`}></i>
                                        <span>Card</span>
                                    </div>

                                    <div className={`bg-white p-2 flex flex-column align-items-center justify-content-center border-round w-full`}>
                                        <i className={`pi pi-mobile text-4xl`}></i>
                                        <span>Mobile</span>
                                    </div>

                                    <div className={`bg-white p-2 flex flex-column align-items-center justify-content-center border-round w-full`}>
                                        <i className={`pi pi-comments text-4xl`}></i>
                                        <span>Fap</span>
                                    </div>
                                    <div onClick={() => (!isShopAttendant ? navigate(CREDIT_SALES_ROUTE_PATH) : true)} className={`btn bg-white p-2 flex flex-column align-items-center justify-content-center border-round w-full`}>
                                        <i className={`pi pi-money-bill text-4xl`}></i>
                                        <span>Debt</span>
                                    </div>

                                    <div onClick={clearEverything} className={`p-button p-2 flex flex-column align-items-center justify-content-center border-round w-full`}>
                                        <i className={`pi pi-sync text-4xl`}></i>
                                        <span>Clear</span>
                                    </div>
                                </div>

                                <div className="mt-3 ">
                                    <Button className="w-full font-semibold primary-btn h-3rem" label="Confirm Purchase" onClick={saveSale} loading={loading} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <PaymentModeForm
                    cart={{
                        cartItems,
                        receivedAmount,
                        totalAmount,
                        balanceGivenOut: receivedAmount - totalAmount
                    }}
                    selectedShop={selectedShop}
                    showPaymentDialog={showPaymentDialog}
                    hidePaymentDialog={hidePaymentDialog}
                    toast={toast}
                    refreshTable={() => {
                        clearEverything();
                        fetchSales();
                    }}
                    selectedCurrency={selectedCurrency}
                />

                <HeldModeForm
                    cart={{
                        cartItems,
                        receivedAmount,
                        totalAmount,
                        balanceGivenOut: receivedAmount - totalAmount
                    }}
                    selectedShop={selectedShop}
                    selectedCurrency={selectedCurrency}
                    hideHeldDialog={hideHeldDialog}
                    showHeldDialog={showHeldDialog}
                    toast={toast}
                    heldTrans={heldTrans}
                    refreshTable={() => {
                        clearEverything();
                        fetchSales();
                    }}
                />

                <SaleSummaryDialog visible={productDialog} hideDialog={hideDialog} selectedSale={selectedSale} />

                <Dialog visible={enterQty} style={{ width: '500px' }} className="p-fluid" header={`Successfull `} modal footer={QtyDialogFooter} onHide={hideQtyDialog}>
                    <div className="">
                        <label className="title">{`${selectedItem?.productName} has been selected`}</label>
                        {!selectedSaleUnit && (
                            <div className="field">
                                <label>Select sale unit</label>
                                <div className="flex flex-wrap gap-3 ">
                                    {saleUnits.map((item) => {
                                        const isSelected = selectedSaleUnit?.productSaleUnitName === item?.productSaleUnitName;

                                        return (
                                            <Button
                                                className={classNames({ 'outline-btn p-button-rounded': !isSelected, 'p-button-rounded': isSelected })}
                                                key={item.productSaleUnitName}
                                                onClick={() => handleSaleUnitChange(item)}
                                                label={item.productSaleUnitName}
                                                style={{ width: 'fit-content' }}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        )}

                        {selectedSaleUnit && (
                            <>
                                <div className="field mt-3">
                                    <label htmlFor="qty">Quantity</label>
                                    <InputNumber className={classNames({ 'p-invalid': submitted2 && quantity < 1 })} id="qty" value={quantity} onValueChange={(e) => setQuantity(e.value)} required autoFocus />
                                    {submitted2 === true && !quantity && <small className="p-error">Quantity is required.</small>}
                                </div>

                                <div className="field ">
                                    <label>Price {selectedItem?.currency && `(${selectedItem?.currency})`}</label>
                                    <InputNumber className={classNames({ 'p-invalid': submitted2 && errors?.costError })} id="qty" value={unitCost} onValueChange={(e) => setUnitCost(e.value)} required />
                                    {submitted2 === true && errors?.costError && <small className="p-error">{errors?.costError}</small>}
                                </div>
                            </>
                        )}
                    </div>
                </Dialog>

                {showPrintDialog && <RecieptPrintDialog saleData={selectedSale} toggleFn={setShowPrintDialog} visible={showPrintDialog} reloadFn={setShowPrintDialog} />}
            </div>
        </div>
    );
}

import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { formatNumberWithCommas, toReadableDate, toReadableTime } from '../../utils/Utils';
import { Paginator } from 'primereact/paginator';
import { TabView, TabPanel } from 'primereact/tabview';
import { UserSessionUtils } from '../../utils/UserSessionUtils';
import { MAXIMUM_RECORDS_PER_PAGE } from '../../constants/Constants';
import { BaseApiService } from '../../utils/BaseApiService';
import SalePayment from '../../components/creditsales/SalePayment';
import SaleSummaryDialog from '../../components/dialogs/SaleSummary';
import DeleteRecordDialog from '../../components/dialogs/DeleteRecordDialog';
import StatusSummary from '../../components/StatusSummary';
import RenderCurrency from '../../components/RenderCurrency';

export default function CreditSales() {
    const toast = useRef(null);
    const dt = useRef(null);

    const [selectedSale, setSelectedSale] = useState(null);
    const [creditSale, setCreditSale] = useState(null);

    const [deleteProductDialog, setDeleteProductDialog] = useState(false);

    const [creditSales, setCreditSales] = useState([]);
    const [showPaymentDialog, setShowPaymentDialog] = useState(false);
    const [productDialog, setProductDialog] = useState(false);

    const [limit, setLimit] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [first, setFirst] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [shops, setShops] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);

    let offset = 0;
    const hideDialog = () => {
        setCreditSale(null);
        setProductDialog(false);
    };

    const fetchCreditSales = async () => {
        setIsLoading(true);
        let searchParameters = {
            searchTerm: searchTerm,
            offset: offset,
            limit: MAXIMUM_RECORDS_PER_PAGE,
            ...(activeIndex > 0 && { shopId: shops[activeIndex]?.id })
        };

        if (UserSessionUtils.getShopOwnerId()) {
            searchParameters.shopOwnerId = UserSessionUtils.getShopOwnerId();
        }

        if (UserSessionUtils.getShopAttendant()) {
            searchParameters.shopId = UserSessionUtils.getShopId();
        }

        new BaseApiService('/credit-sales')
            .getRequestWithJsonResponse(searchParameters)
            .then((response) => {
                setCreditSales(response?.records);
                setTotalRecords(response?.totalItems);
                setIsLoading(false);
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: error?.message, life: 3000 });
                setIsLoading(false);
            });
    };

    const fetchCreditSaleById = async (id) => {
        setIsLoading(true);
        let searchParameters = {
            offset: 0,
            limit: MAXIMUM_RECORDS_PER_PAGE
        };

        if (UserSessionUtils.getShopOwnerId()) {
            searchParameters.shopOwnerId = UserSessionUtils.getShopOwnerId();
        }

        if (UserSessionUtils.getShopAttendant()) {
            searchParameters.shopId = UserSessionUtils.getShopId();
        }

        new BaseApiService('/shop-sales/' + id)
            .getRequestWithJsonResponse(searchParameters)
            .then((response) => {
                setSelectedSale(response);
                setIsLoading(false);
                setProductDialog(true);
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: error?.message, life: 3000 });
                setIsLoading(false);
            });
    };

    const fetchShops = async () => {
        let searchParameters = { offset: 0, limit: 0 };

        if (UserSessionUtils.getShopOwner()) {
            searchParameters.shopOwnerId = UserSessionUtils.getShopOwnerId();
        }
        await new BaseApiService('/shops')
            .getRequestWithJsonResponse(searchParameters)
            .then(async (response) => {
                if (response?.records?.length > 1) {
                    setShops([{ name: 'All shops', id: UserSessionUtils.getShopOwnerId() }, ...response?.records.reverse()]);
                    return;
                }
                setShops(response.records);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchShops();
    }, []);

    const header = (
        <div className="flex flex-column md:flex-row md:align-items-center">
            <h5 className="m-0 line relative">Debt Sales</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left ml-5">
                <div className="p-inputgroup flex-1">
                    <InputText placeholder="Search" onInput={(e) => setSearchTerm(e.target.value)} />
                    <Button icon="pi pi-search" className="primary-btn" onClick={fetchCreditSales} />
                </div>
            </span>
        </div>
    );

    const onPageChange = (e) => {
        offset = e.page * MAXIMUM_RECORDS_PER_PAGE;
        setFirst(e.first);
        setLimit(MAXIMUM_RECORDS_PER_PAGE);

        fetchCreditSales();
    };
    const template2 = {
        layout: 'CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 }
            ];

            return (
                <React.Fragment>
                    <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}></span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                    <span className="mr-auto"></span>
                </React.Fragment>
            );
        },
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: 'auto', textAlign: 'center' }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} credit sales
                </span>
            );
        }
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className=" flex justify-content-center md:align-items-center ">
                <Button
                    icon="pi pi-eye"
                    className="p-button-rounded p-button-text p-button-success"
                    onClick={() => {
                        fetchCreditSaleById(rowData.sale.id);
                    }}
                />
                <Button
                    icon="bx bx-money-withdraw"
                    className="p-button-rounded p-button-text p-button-info"
                    onClick={() => {
                        const bal = rowData?.amountLoaned - rowData?.amountRepaid;
                        if (bal <= 0) {
                            toast.current.show({ severity: 'info', summary: 'Alert', detail: 'Sale is fully paid', life: 3000 });
                        } else {
                            setCreditSale(rowData);
                            setShowPaymentDialog(true);
                        }
                    }}
                />
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-text p-button-danger "
                    onClick={() => {
                        setCreditSale(rowData);
                        setDeleteProductDialog(true);
                    }}
                />
            </div>
        );
    };

    const contactBodyTemplate = (rowData) => {
        const { shopClient } = rowData;
        return (
            <>
                <span className="p-column-title">Client </span>
                {shopClient?.phoneNumber}
            </>
        );
    };

    const balanceBodyTemplate = (rowData) => {
        const isFullyPaid = rowData?.balance <= 0;
        return <>{!isFullyPaid ? <RenderCurrency value={rowData?.amountLoaned - rowData?.amountRepaid} currencySymbol={rowData?.currency} /> : 'Fully paid'}</>;
    };

    const amountBodyTemplate = (rowData) => {
        return (
            <>
                <RenderCurrency value={rowData?.amountLoaned} currencySymbol={rowData?.currency} />
            </>
        );
    };

    const amountPaidBodyTemplate = (rowData) => {
        return (
            <>
                <RenderCurrency value={rowData?.amountRepaid} currencySymbol={rowData?.currency} />
            </>
        );
    };

    const createdByFullNameBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.createdByFullName} <br />
                <span className="text-sm opacity-80"> {rowData.sale.shop.name}</span>
            </>
        );
    };

    const dateCreatedBodyTemplate = (rowData) => {
        return (
            <>
                {toReadableDate(rowData.dateCreated)} <br />
                {toReadableTime(rowData.dateCreated)}
            </>
        );
    };

    const shopNameBodyTemplate = (rowData) => {
        return (
            <>
                {rowData?.shopClient?.fullName}
                <br />
                {rowData?.serialNumber}
            </>
        );
    };

    const dynamicTabs = () => {
        if (shops?.length > 1&& !UserSessionUtils.getSuperAdmin()) {
            return (
                <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} className='h-3rem'>
                    {shops.map((shop, index) => (
                        <TabPanel key={index} header={shop.name} className="h-auto"></TabPanel>
                    ))}
                </TabView>
            );
        }
    };

    useEffect(() => {
        fetchCreditSales();
    }, [activeIndex]);

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="py-2">
                    <div className="grid">
                        <StatusSummary title="Total" value={formatNumberWithCommas(0)} />
                        <StatusSummary title="Value" value="0" iconName="pi-map-marker" />
                        <StatusSummary title="Items" value="0" iconName="pi-inbox" />
                        <StatusSummary title="Amount" value="0" iconName="pi-inbox" />
                    </div>
                </div>
                <div className="py-2 -mt-3">
                    <div className="grid">
                        <div className="col-12 md:col-12 lg:col-12">
                            <div className="card">
                                <Toast ref={toast} />

                                {header}

                                <DataTable loading={isLoading} ref={dt} value={creditSales} dataKey="id" rows={10} className="datatable-responsive" emptyMessage="No shop sales found." header={dynamicTabs} size="small" resizableColumns>
                                    <Column field="serialNumber" header="Debtor" body={shopNameBodyTemplate} className="text-left" />

                                    <Column header="Mobile" className="text-center" headerStyle={{ textAlign: 'center' }} body={contactBodyTemplate} />

                                    <Column header="Debt" className="text-center" headerStyle={{ textAlign: 'center' }} body={amountBodyTemplate} />

                                    <Column header="Paid" className="text-center" headerStyle={{ textAlign: 'center' }} body={amountPaidBodyTemplate} />

                                    <Column header="Balance" className="text-center" headerStyle={{ textAlign: 'center' }} body={balanceBodyTemplate} />

                                    <Column field="createdByFullName" body={createdByFullNameBodyTemplate} header="Served by" />
                                    <Column field="dateCreated" header="Sold on" body={dateCreatedBodyTemplate} className="text-left" />
                                    <Column body={actionBodyTemplate} headerStyle={{ textAlign: 'center' }} header="Actions" className="text-center" />
                                </DataTable>
                                <Paginator template={template2} first={first} rows={MAXIMUM_RECORDS_PER_PAGE} totalRecords={totalRecords} alwaysShow={true} onPageChange={onPageChange} />
                            </div>
                        </div>
                    </div>
                </div>

                <SaleSummaryDialog visible={productDialog} hideDialog={hideDialog} selectedSale={selectedSale} />

                <SalePayment
                    toast={toast}
                    refresh={async () => {
                        fetchCreditSales();
                        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Sale payment made successfully', life: 3000 });
                    }}
                    viewSalePayment={showPaymentDialog}
                    setViewSalePayment={setShowPaymentDialog}
                    selectedSale={creditSale}
                />

                <DeleteRecordDialog
                    isLoading={isLoading}
                    selectedRecordName={`Sale record ${creditSale?.id}`}
                    setDeleteProductDialog={setDeleteProductDialog}
                    deleteProductDialog={deleteProductDialog}
                    setSelectedRecord={setSelectedSale}
                    apiUrl={'/shop-sales/' + creditSale?.sale.id}
                    showForm
                    setIsLoading={setIsLoading}
                    onComplete={() => {
                        fetchCreditSales();
                        setSelectedSale(null);
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Sale record deleted', life: 3000 });
                        setIsLoading(false);
                    }}
                />
            </div>
        </div>
    );
}

import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { BaseApiService } from '../utils/BaseApiService';
import { Dropdown } from 'primereact/dropdown';
import { convertDateFormat, toReadableDate, toReadableTime } from '../utils/Utils';
import { UserSessionUtils } from '../utils/UserSessionUtils';
import { MAXIMUM_RECORDS_PER_PAGE } from '../constants/Constants';
import { Paginator } from 'primereact/paginator';
import { formatNumberWithCommas } from '../utils/Utils';
import DeleteRecordDialog from '../components/dialogs/DeleteRecordDialog';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { Accordion, AccordionTab } from 'primereact/accordion';
import EditSaleDialog from '../components/dialogs/EditSaleDialog';
import StatusSummary from '../components/StatusSummary';
import SaleSummaryDialog from '../components/dialogs/SaleSummary';
import { useLocation } from 'react-router-dom';
import StorageParams from '../constants/StorageParams';
import RenderCurrency from '../components/RenderCurrency';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dialog } from 'primereact/dialog';

export default function HeldSalesDesk() {
    const toast = useRef(null);
    const dt = useRef(null);

    const [heldTrans, setHeldTrans] = useState(JSON.parse(localStorage.getItem(StorageParams.HELD_TRANSACTIONS)) || []);
    const [limit, setLimit] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [first, setFirst] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [deleteTransactionDialog, setDeleteTransactionDialog] = useState(false);
    const [income, setIncome] = useState(0);
    const [salesValue, setSalesValue] = useState(0);
    const [capital, setCapital] = useState(0);
    const [dates, setDates] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [shops, setShops] = useState([]);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [expandedRows, setExpandedRows] = useState(null);

    let offset = 0;
    const { state } = useLocation();
    const calendarRef = useRef(null);

    const fetchShops = async () => {
        let searchParameters = { offset: 0, limit: 0 };

        if (UserSessionUtils.getShopOwner()) {
            searchParameters.shopOwnerId = UserSessionUtils.getShopOwnerId();
        }

        if(UserSessionUtils.getShopAttendant()){
            return
        }
        await new BaseApiService('/shops')
            .getRequestWithJsonResponse(searchParameters)
            .then(async (response) => {
                if (response?.records?.length > 1) {
                    setShops([{ name: 'All shops', id: UserSessionUtils.getShopOwnerId() }, ...response?.records.reverse()]);
                    return;
                }
                setShops(response.records);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    };

    const onPageChange = (e) => {
        offset = e.page * MAXIMUM_RECORDS_PER_PAGE;
        setFirst(e.first);
        setLimit(MAXIMUM_RECORDS_PER_PAGE);
    };

    useEffect(() => {
        if (!UserSessionUtils.getSuperAdmin()) {
            fetchShops();
        }
    }, []);

    useEffect(() => {}, [activeIndex]);

    const header = (
        <div className="flex flex-column md:flex-row md:align-items-center">
            <h5 className="m-0">Shop Held Sales</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left  ml-5">
                <div className="p-inputgroup flex-1">
                    <Calendar ref={calendarRef} className="w-full" selectionMode="range" readOnlyInput hideOnRangeSelection maxDate={new Date()} value={dates} onChange={(e) => setDates(e.value)} placeholder="Date" dateFormat="dd-M-yy" />
                    <Button icon={!dates ? 'pi pi-calendar' : 'pi pi-times-circle'} className="primary-btn" onClick={() => setDates(null)} />
                </div>
            </span>
            <span className="block mt-2 md:mt-0 p-input-icon-left ml-5">
                <div className="p-inputgroup flex-1">
                    <InputText placeholder="Search" onInput={(e) => setSearchTerm(e.target.value)} />
                    <Button icon="pi pi-search" className="primary-btn" />
                </div>
            </span>
        </div>
    );

    const template2 = {
        layout: 'CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 }
            ];

            return (
                <React.Fragment>
                    <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}></span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                    <span className="mr-auto"></span>
                </React.Fragment>
            );
        },
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: 'auto', textAlign: 'center' }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} shop held sales
                </span>
            );
        }
    };

    const amountBodyTemplate = (rowData) => {
        return (
            <>
                <RenderCurrency value={rowData.sold} currencySymbol={rowData?.currency} />
            </>
        );
    };

    const amountBodyTemplateS = (rowData) => {
        return (
            <>
                <RenderCurrency value={rowData.unitCost} currencySymbol={rowData?.currency} />
            </>
        );
    };

    const receivedBodyTemplate = (rowData) => {
        return (
            <>
                <RenderCurrency value={rowData.received} currencySymbol={rowData?.currency} />
            </>
        );
    };

    const phoneNumberBodyTemplate = (rowData) => {

        return (
            <>
                <span>{rowData.clientNumber === null ? 'Not provided' : rowData.clientNumber}</span>
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex md:flex-row md:align-items-center justify-content-center">
                <Button icon="pi pi-trash" className="p-button-rounded p-button-text p-button-danger mt-2" onClick={() => confirmDeleteLead(rowData.id)} />
            </div>
        );
    };

    const confirmDeleteLead = (data) => {
        setDeleteTransactionDialog(true);
        setSelectedTransaction(data);
    };

    const deleteTransaction = () => {
        let tempHeld = heldTrans.filter((transaction) => transaction.id !== selectedTransaction);
        setHeldTrans([...tempHeld]);
        localStorage.setItem(StorageParams.HELD_TRANSACTIONS, JSON.stringify(tempHeld));
        setDeleteTransactionDialog(false);
    };

    const hideShopDialog = () => {
        setDeleteTransactionDialog(false);
    };

    const deleteTransactionDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="outline-btn" onClick={hideShopDialog} />
            <Button label="Yes" icon="pi pi-check" className="primary-btn" onClick={deleteTransaction} />
        </>
    );

    const rowExpansionTemplate = (data) => {
        return (
            <div className="p-3">
                <h5>Cart Items for {data.clientName}</h5>
                <DataTable value={data.cart}>
                    <Column field="productName" header="Item"></Column>
                    <Column field="barcode" header="Barcode"></Column>
                    <Column field="quantity" header="Qty"></Column>
                    <Column field="unitCost" header="Cost" body={amountBodyTemplateS}></Column>
                </DataTable>
            </div>
        );
    };

    const dynamicTabs = () => {
        if (shops?.length > 1) {
            return (
                <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                    {shops.map((shop, index) => (
                        <TabPanel key={index} header={shop.name} className="h-auto"></TabPanel>
                    ))}
                </TabView>
            );
        }
    };

    const filterFunc = () => {
        let transactions;
        if (activeIndex === 0) {
            return (transactions = heldTrans.filter((transaction) => transaction.shopOwnerId === shops[activeIndex].id));
        } else if (shops?.length > 1) {
            return (transactions = heldTrans.filter((transaction) => transaction.shopId === shops[activeIndex].id));
        } else {
            return (transactions = heldTrans.filter((transaction) => transaction.shopId === shops[0].id));
        }
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="py-2 -mt-3">
                    <div className="grid">
                        <StatusSummary iconName="pi pi-shopping-cart " title="Txns" value={formatNumberWithCommas(totalRecords)} />
                        <StatusSummary title="Held Sales" value={formatNumberWithCommas(salesValue)} iconName="bx bx-receipt" />
                        <StatusSummary title="Capital" value={formatNumberWithCommas(Math.round(capital))} iconName="pi pi-money-bill" />
                        <StatusSummary title="Income" value={formatNumberWithCommas(income)} iconName="bx bx-wallet" />
                    </div>
                </div>

                <div className="py-2 -mt-2">
                    <Accordion>
                        <AccordionTab className="p-fluid" header="Filter sale">
                            <div className="formgrid grid">
                                <div className="field col">
                                    <label>Shop name</label>
                                    <Dropdown />
                                </div>
                                <div className="field col">
                                    <label htmlFor="purchasePrice">Transaction id</label>
                                    <InputNumber />
                                </div>
                                <div className="field col">
                                    <label htmlFor="purchasePrice">Items</label>
                                    <Dropdown />
                                </div>
                            </div>

                            <div className="formgrid grid">
                                <div className="field col">
                                    <label htmlFor="purchasePrice">Received</label>
                                    <InputNumber />
                                </div>
                                <div className="field col">
                                    <label htmlFor="purchasePrice">Amount</label>
                                    <InputNumber />
                                </div>
                                <div className="field col">
                                    <label htmlFor="purchasePrice">Balance</label>
                                    <InputNumber />
                                </div>
                            </div>
                            <div className="formgrid grid">
                                <div className="field col">
                                    <label htmlFor="purchasePrice">Sold by</label>
                                    <Dropdown />
                                </div>

                                <div className="field col">
                                    <label htmlFor="purchasePrice">Start date</label>
                                    <Calendar />
                                </div>

                                <div className="field col">
                                    <label htmlFor="purchasePrice">End date</label>
                                    <Calendar />
                                </div>
                            </div>
                            <div className="flex justify-content-end">
                                <Button label="Clear" icon="pi pi-times" className="p-button-text " style={{ color: 'black', border: '1px solid black', margin: '5px', width: 'fit-content' }} />
                                <Button label="Filter" icon="pi pi-check" className="p-button-text" style={{ color: '#FFEB3B', border: '1px solid black', backgroundColor: 'black', margin: '5px', width: 'fit-content' }} />
                            </div>
                        </AccordionTab>
                    </Accordion>
                </div>
                <div className="py-2 -mt-1">
                    <div className="grid">
                        <div className="col-12 md:col-12 lg:col-12">
                            <div className="card">
                                <Toast ref={toast} />

                                {header}

                                <DataTable
                                    header={dynamicTabs}
                                    loading={isLoading}
                                    ref={dt}
                                    value={shops.length > 1 ? filterFunc() : heldTrans}
                                    dataKey="id"
                                    rows={10}
                                    className="datatable-responsive"
                                    emptyMessage="No held sales found."
                                    size="small"
                                    onRowToggle={(e) => setExpandedRows(e.data)}
                                    expandedRows={expandedRows}
                                    rowExpansionTemplate={rowExpansionTemplate}
                                >
                                    <Column expander={true} style={{ width: '5rem' }} />
                                    <Column field="clientName" header="Client Name " style={{ width: '10%' }} />
                                    <Column field="clientNumber" header="Phone Number" body={phoneNumberBodyTemplate} className="text-center" headerStyle={{ textAlign: 'center' }} />
                                    <Column header="Received" field="received" body={receivedBodyTemplate} className="text-right" headerStyle={{ textAlign: 'right' }} />
                                    <Column header="Amount" field="sold" body={amountBodyTemplate} className="text-right" headerStyle={{ textAlign: 'right' }} />
                                    <Column header="Created By" field="createdBy" className="text-center" headerStyle={{ textAlign: 'right' }} />
                                    <Column header="Actions" className="text-center" body={actionBodyTemplate} headerStyle={{ textAlign: 'right' }} />
                                </DataTable>
                                <Paginator template={template2} first={first} rows={MAXIMUM_RECORDS_PER_PAGE} totalRecords={totalRecords} alwaysShow={false} onPageChange={onPageChange} />
                            </div>
                        </div>
                    </div>
                </div>

                <Dialog visible={deleteTransactionDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteTransactionDialogFooter} onHide={hideShopDialog}>
                    <div className="flex align-items-center justify-content-center">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />

                        <span>Are you sure you want to delete</span>
                    </div>
                </Dialog>
            </div>
        </div>
    );
}

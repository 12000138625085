import React, { useEffect, useRef } from 'react';
import { MarkerClusterer } from '@googlemaps/markerclusterer';

const DEFAULT_CENTER = { lat: 2.5816, lng: 18.3152 };
const DEFAULT_ZOOM = 3.8;
const DEMO_MAP_ID = 'DEMO_MAP_ID';

export default function GoogleMapWithMarkers({ className, streetViewLocation, locations }) {
    const mapRef = useRef(null);
    const streetViewRef = useRef(null);

    useEffect(() => {
        const initMap = async () => {
            const { AdvancedMarkerElement, PinElement } = await window.google.maps.importLibrary('marker');

            const map = new window.google.maps.Map(mapRef.current, {
                center: DEFAULT_CENTER,
                zoom: DEFAULT_ZOOM,
                mapId: DEMO_MAP_ID
            });

            const infoWindow = new window.google.maps.InfoWindow({
                content: '',
                disableAutoPan: true
            });

            const labels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            const markers = locations.map((position, i) => {
                const pinGlyph = new PinElement({
                    glyph: position.label,
                    glyphColor: 'black',
                });
                const marker = new AdvancedMarkerElement({
                    position,
                    content: pinGlyph.element
                });

                marker.addListener('click', () => {
                    infoWindow.setContent(`${position.lat}, ${position.lng}`);
                    infoWindow.open(map, marker);
                });

                return marker;
            });

            new MarkerClusterer({ markers, map });

            if (streetViewLocation) {
                const panorama = new window.google.maps.StreetViewPanorama(streetViewRef.current, {
                    position: streetViewLocation,
                    pov: { heading: 165, pitch: 0 },
                    zoom: 1
                });
                map.setStreetView(panorama);
            }
        };

        if (window.google) {
            initMap();
        }
    }, [streetViewLocation]);

    return (
        <div className={className} style={{ display: 'flex', width: '100%', height: '700px' }}>
            <div ref={mapRef} style={{ width: streetViewLocation ? '70%' : '100%', height: '100%' }} />
            {streetViewLocation && <div ref={streetViewRef} style={{ width: '30%', height: '100%' }} />}
        </div>
    );
}

import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import FormFooter from '../FormFooter';
import ChipButton from '../ChipButton';
import { downloadProductExcelSheet } from '../../utils/DownloadUtils';

const DownloadWorkBookModal = ({ shops = [], showModal = false, setShowModal }) => {
    const [loading, setLoading] = useState(false);

    const [selectedShop, setSelectedShop] = useState(null);

    const hideDialog = () => {
        setShowModal(false);
        setSelectedShop(null);
    };

    const handleDownload = async () => {
        if (selectedShop !== null) {
            setLoading(true);
            await downloadProductExcelSheet(selectedShop);
            setLoading(false);
            hideDialog();
        }
    };

    return (
        <Dialog
            visible={showModal}
            style={{ width: '700px' }}
            header="Select a shop"
            modal
            className="p-fluid"
            //
            footer={() => <FormFooter labelRight="Download" onClickLeft={hideDialog} onClickRight={handleDownload} isLoading={loading} />}
            onHide={hideDialog}
        >
            <div className="flex flex-wrap gap-3 mt-3 mb-2">
                {shops
                    .filter((i) => !i.name.includes('All'))
                    .map((s) => (
                        <ChipButton label={s.name} isSelected={selectedShop?.name === s?.name} onClick={() => setSelectedShop(s)} />
                    ))}
            </div>
        </Dialog>
    );
};

export default DownloadWorkBookModal;

import { Dialog } from 'primereact/dialog';
import React, { useState, useEffect, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { BaseApiService } from '../../utils/BaseApiService';
import { Messages } from 'primereact/messages';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { showErrorMessage } from '../../constants/ErrorMessages';
import FormFooter from '../FormFooter';
import { COUNTRIES_ENDPOINT, LOOK_UPS_ENDPOINT, SUBSCRIPTIONS_ENDPOINT } from '../../utils/EndpointsUtils';
import { InputTextarea } from 'primereact/inputtextarea';
import { hasNull } from '../../utils/Utils';

function SubscriptionForm({ isVisible = false, selectedRecord, toast, refreshTable, setShowForm, setSelectedRecord }) {
    const [isLoading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [name, setName] = useState(null);
    const [duration, setDuration] = useState(0);
    const [cost, setCost] = useState(0);
    const [description, setDescription] = useState(null);
    const [selectedCategory, setSelectdCategory] = useState(null);
    const [countries, setCountries] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [submitted, setSubmitted] = useState(false);

    const dialogMessage = useRef();

    const fetchShopCategories = async () => {
        const searchParameters = { offset: 0, limit: 0, commaSeparatedTypeIds: [6] };
        setLoading(true);
        await new BaseApiService(LOOK_UPS_ENDPOINT)
            .getRequestWithJsonResponse(searchParameters)
            .then((response) => {
                setCategories(response?.records);
                setLoading(false);
            })
            .catch((e) => {
                console.error(e);
                setLoading(false);
            });
    };

    const fetchCountries = async () => {
        await new BaseApiService(COUNTRIES_ENDPOINT)
            .getRequestWithJsonResponse({ offset: 0, limit: 0 })
            .then(async (response) => {
                setCountries(response.records);
            })
            .catch((error) => {});
    };

    const populateForm = () => {
        clearForm();
        if (selectedRecord) {
            setName(selectedRecord?.name);
            setDuration(selectedRecord?.durationInDays);
            setCost(selectedRecord?.costPerSubscription);

            setDescription(selectedRecord?.description);

            setSelectdCategory(categories?.find((i) => i.id === selectedRecord?.shopCategory?.id)?.id);
            setSelectedCountry(countries?.find((i) => i.id === selectedRecord?.country?.id));
        }
    };

    const clearForm = () => {
        setCost(0);
        setDescription('');
        setName('');
        setDuration(0);
        setSelectdCategory(null);
        setSelectedCountry(null);
        setLoading(false);
        setSubmitted(false);
    };

    const hideDialog = () => {
        clearForm();
        setShowForm();
        setSelectedRecord(null);
    };

    const onSave = async () => {
        setSubmitted(true);
        const payload = {
            id: selectedRecord?.id || 0,
            name: name,
            description: description,
            durationInDays: duration,
            costPerSubscription: cost,
            shopCategoryId: selectedCategory,
            countryId: selectedCountry?.id,
            status: 'ACTIVE'
        };

        if (!hasNull(payload)) {
            setLoading(true);
            await new BaseApiService(SUBSCRIPTIONS_ENDPOINT)
                .saveRequestWithJsonResponse(payload, false)
                .then((resp) => {
                    refreshTable();
                    hideDialog();
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Subscription details saved', life: 3000 });
                    setSubmitted(false);
                })
                .catch((e) => {
                    showErrorMessage(dialogMessage, e?.message);
                    setLoading(false);
                    setSubmitted(false);
                });
        }
    };

    useEffect(() => {
        fetchShopCategories();
        fetchCountries();
    }, []);

    useEffect(() => {
        populateForm();
    }, [selectedRecord]);

    return (
        <Dialog
            footer={() => <FormFooter isLoading={isLoading} onClickRight={onSave} onClickLeft={hideDialog} />}
            header={'Subscription details'}
            visible={isVisible}
            className="p-fluid w-max"
            //
            style={{ maxWidth: '600px' }}
            modal
            onHide={hideDialog}
        >
            <div className="">
                <Messages ref={dialogMessage} style={{ width: '100%' }} />

                <div className="field">
                    <label>Subscription name</label>

                    <InputText value={name} onChange={(e) => setName(e.target.value)} />
                    {submitted && !name && <small className="p-error">Name is required.</small>}
                </div>
                <div className="form-grid grid">
                    <div class="col-12 md:col-6 lg:col-6 ">
                        <label>Country</label>

                        <Dropdown
                            id="country"
                            //
                            value={selectedCountry}
                            options={countries}
                            onChange={(e) => setSelectedCountry(e.target.value)}
                            optionLabel={'name'}
                            filter
                            filterBy="name"
                            placeholder="Select Country"
                        />

                        {submitted && !selectedCountry && <small className="p-error">Country is required.</small>}
                    </div>

                    <div class="col-12 md:col-6 lg:col-6 ">
                        <label>Shop Category</label>
                        <Dropdown
                            //
                            value={selectedCategory}
                            options={categories}
                            onChange={(e) => {
                                setSelectdCategory(e.value);
                            }}
                            optionLabel={'value'}
                            optionValue="id"
                            filter
                            placeholder="Select a category"
                        />
                        {submitted && !selectedCategory && <small className="p-error">Shop category is required.</small>}
                    </div>
                </div>

                <div className="form-grid grid">
                    <div class="col-12 md:col-6 lg:col-6 ">
                        <label>Duration (days)</label>

                        <InputNumber value={duration} onChange={(e) => setDuration(e.value)} />
                        {submitted && !duration && <small className="p-error">Duration is required.</small>}
                    </div>

                    <div class="col-12 md:col-6 lg:col-6 ">
                        <label>Cost</label>

                        <InputNumber value={cost} onChange={(e) => setCost(e.value)} />
                        {submitted && !cost && <small className="p-error">Cost is required.</small>}
                    </div>
                </div>

                <div className="field">
                    <label>Description</label>

                    <InputTextarea autoResize={false} style={{ height: 100 }} value={description} onChange={(e) => setDescription(e.target.value)} />

                    {submitted && !description && <small className="p-error">Description is required.</small>}
                </div>
            </div>
        </Dialog>
    );
}

export default SubscriptionForm;

import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { BaseApiService } from '../../utils/BaseApiService';
import { Dropdown } from 'primereact/dropdown';
import { toReadableDateTime, toReadableTime } from '../../utils/Utils';
import { MAXIMUM_RECORDS_PER_PAGE } from '../../constants/Constants';
import { Paginator } from 'primereact/paginator';
import { formatNumberWithCommas } from '../../utils/Utils';
import StatusSummary from '../../components/StatusSummary';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import TimeStamp from '../../components/TimeStamp';
import { SHOP_REPORTS_ROUTE_PATH } from '../../utils/NavigationRoutes';
import { generatePath, useNavigate } from 'react-router-dom';

export default function AdminReports() {
    const toast = useRef(null);
    const dt = useRef(null);

    const navigate = useNavigate();

    const [productDialog, setProductDialog] = useState(false);
    const [selectedSale, setSelectedSale] = useState({}); //the table row
    const [limit, setLimit] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [first, setFirst] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [shops, setShops] = useState([]);
    const [totalTxns, setTotalTxns] = useState(0);

    let offset = 0;

    const fetchShops = async () => {
        setIsLoading(true);
        let searchParameters = {
            offset: offset,
            limit: MAXIMUM_RECORDS_PER_PAGE,
            ...(searchTerm !== '' && { searchTerm: searchTerm })
        };

        new BaseApiService('/shops')
            .getRequestWithJsonResponse(searchParameters)
            .then(async (response) => {
                setShops([]);
                let dataSet1 = [];
                // setShops(response.records);

                for (let shop of response.records) {
                    let shopData = { ...shop };
                    await new BaseApiService(`/shop-sales`)
                        .getRequestWithJsonResponse({
                            offset: 0,
                            limit: 1,
                            shopId: shop?.id
                        })

                        .then(async (response) => {
                            const { totalItems, records } = response;
                            shopData.txns = totalItems;
                            shopData.lastTxn = records[0]?.dateCreated;

                            await new BaseApiService(`/shops/attendants`)
                                .getRequestWithJsonResponse({
                                    offset: 0,
                                    limit: 0,
                                    shopId: shop?.id
                                })

                                .then(async (response) => {
                                    shopData.attendants = response.totalItems;
                                });
                            await new BaseApiService('/shop-products')
                                .getRequestWithJsonResponse({
                                    offset: 0,
                                    limit: 1,
                                    shopId: shop?.id
                                })

                                .then(async (response) => {
                                    shopData.items = response.totalItems;
                                });
                        });
                    dataSet1.push(shopData);
                }
                setShops(dataSet1);
                setTotalTxns(dataSet1.reduce((a, b) => a + b.txns, 0));
                setIsLoading(false);
                setTotalRecords(response.totalItems);
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: error?.message, life: 3000 });
                setIsLoading(false);
            });
    };

    const handleSelection = (rowData) => {
        const { name } = rowData.value;
        let formartedName = String(name).split(' ').join('_');
        navigate(generatePath(SHOP_REPORTS_ROUTE_PATH, { shopName: formartedName }), {
            state: {
                ...rowData.value
            }
        });
    };

    const onPageChange = (e) => {
        offset = e.page * MAXIMUM_RECORDS_PER_PAGE;
        setFirst(e.first);
        setLimit(MAXIMUM_RECORDS_PER_PAGE);

        fetchShops();
    };

    useEffect(() => {
        fetchShops();
    }, []);

    const header = (
        <div className="flex flex-column md:flex-row md:align-items-center">
            <h5 className="m-0 line relative">Shop Sales</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left ml-5">
                <div className="p-inputgroup flex-1">
                    <InputText placeholder="Search" onInput={(e) => setSearchTerm(e.target.value)} />
                    <Button icon="pi pi-search" className="primary-btn" onClick={fetchShops} />
                </div>
            </span>
        </div>
    );

    const template2 = {
        layout: 'CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 }
            ];

            return (
                <React.Fragment>
                    <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}></span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                    <span className="mr-auto"></span>
                </React.Fragment>
            );
        },
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: 'auto', textAlign: 'center' }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} Shop details
                </span>
            );
        }
    };

    const attendantsBodyTemplete = (rowData) => {
        return (
            <>
                <span className="p-column-title">Attendants</span>
                {formatNumberWithCommas(rowData.attendants)}
            </>
        );
    };

    const itemsBodyTemplete = (rowData) => {
        return (
            <>
                <span className="p-column-title">Items</span>
                {formatNumberWithCommas(rowData?.items)}
            </>
        );
    };

    const lastTxnBody = (rowData) => {
        return <TimeStamp time={rowData.lastTxn} title="Last Txn" />;
    };
    const txnBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Transactions</span>
                {formatNumberWithCommas(rowData.txns)}
            </>
        );
    };

    const shopOnwerBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Shop Owner</span>
                {rowData.ownerName}
            </>
        );
    };

    const shopBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Shop</span>
                <span className="font-medium"> {rowData.name}</span> <br />
                <span className="text-sm"> ID: {rowData.id}</span>{' '}
            </>
        );
    };

    const hideDialog = () => {
        setSelectedSale({});
        setProductDialog(false);
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="py-2">
                    <div className="grid">
                        <StatusSummary iconName="pi pi-shopping-cart " title="Txns" value={formatNumberWithCommas(totalTxns)} />
                        <StatusSummary title="Sales" value={totalRecords} iconName="bx bx-receipt" />
                        <StatusSummary title="Capital" value={formatNumberWithCommas(totalRecords)} iconName="pi pi-money-bill" />
                        <StatusSummary title="Income" value={formatNumberWithCommas(totalRecords)} iconName="bx bx-wallet" />
                    </div>
                </div>

                <div className="py-2 -my-2">
                    <Accordion>
                        <AccordionTab className="p-fluid" header="Filter sale">
                            <div className="formgrid grid">
                                <div className="field col">
                                    <label>Shop name</label>
                                    <Dropdown />
                                </div>
                                <div className="field col">
                                    <label htmlFor="purchasePrice">Transaction id</label>
                                    <InputNumber />
                                </div>
                                <div className="field col">
                                    <label htmlFor="purchasePrice">Items</label>
                                    <Dropdown />
                                </div>
                            </div>

                            <div className="formgrid grid">
                                <div className="field col">
                                    <label htmlFor="purchasePrice">Recieved</label>
                                    <InputNumber />
                                </div>
                                <div className="field col">
                                    <label htmlFor="purchasePrice">Amount</label>
                                    <InputNumber />
                                </div>
                                <div className="field col">
                                    <label htmlFor="purchasePrice">Balance</label>
                                    <InputNumber />
                                </div>
                            </div>
                            <div className="formgrid grid">
                                <div className="field col">
                                    <label htmlFor="purchasePrice">Sold by</label>
                                    <Dropdown />
                                </div>

                                <div className="field col">
                                    <label htmlFor="purchasePrice">Start date</label>
                                    <Calendar />
                                </div>

                                <div className="field col">
                                    <label htmlFor="purchasePrice">End date</label>
                                    <Calendar />
                                </div>
                            </div>
                            <div className="flex justify-content-end">
                                <Button label="Clear" icon="pi pi-times" className="p-button-text " style={{ color: 'black', border: '1px solid black', margin: '5px', width: 'fit-content' }} />
                                <Button label="Filter" icon="pi pi-check" className="p-button-text" style={{ color: '#FFEB3B', border: '1px solid black', backgroundColor: 'black', margin: '5px', width: 'fit-content' }} />
                            </div>
                        </AccordionTab>
                    </Accordion>
                </div>
                <div className="py-2 ">
                    <div className="card">
                        <Toast ref={toast} />

                        <DataTable
                            loading={isLoading}
                            ref={dt}
                            value={shops}
                            dataKey="id"
                            rows={10}
                            className="datatable-responsive"
                            emptyMessage="No data found."
                            header={header}
                            selectionMode="single"
                            size="small"
                            resizableColumns
                            onSelectionChange={handleSelection}
                        >
                            <Column field="shopName" header="Shop" body={shopBodyTemplate} className="text-left " style={{ width: '10%' }} />
                            <Column header="Txns" body={txnBodyTemplate} className="text-center" style={{ width: '10%' }} headerStyle={{ textAlign: 'center' }} />

                            <Column field="items" header="Items" body={itemsBodyTemplete} className="text-center" headerStyle={{ textAlign: 'center' }} style={{ width: '10%' }} />
                            {/* <Column field="categories" header="Categories" body={categoryBodyTemplate} className="text-center" style={{ width: '10%' }}/> */}
                            <Column header="Attendants" body={attendantsBodyTemplete} className="text-center" headerStyle={{ textAlign: 'center' }} style={{ width: '10%' }} />

                            <Column body={shopOnwerBodyTemplate} header="Shop Owner" className="text-left" style={{ width: '10%' }} />
                            <Column header="Last Txn" body={lastTxnBody} className="text-left" style={{ width: '10%' }} />
                        </DataTable>
                        <Paginator template={template2} first={first} rows={MAXIMUM_RECORDS_PER_PAGE} totalRecords={totalRecords} alwaysShow={false} onPageChange={onPageChange} />
                    </div>
                </div>
                <Dialog visible={productDialog} style={{ width: '700px' }} header="Sale Summary" modal className="p-fluid" onHide={hideDialog}>
                    <div className="field">
                        <div>
                            <label className="mt-3">
                                Created By: <b>{selectedSale.createdByFullName}</b>
                            </label>
                        </div>
                        <br />
                        <div>
                            <label className="mt-3">
                                Created on: <b>{toReadableDateTime(selectedSale.dateCreated)}</b>
                            </label>
                        </div>
                        <DataTable value={selectedSale.lineItems} dataKey="id" className="mt-3" emptyMessage="No items for sale found." size="small" resizableColumns>
                            <Column field="shopProductName" header="Product Name" className="text-left" />
                            <Column field="quantity" header="Quantity" className="text-center" />
                            <Column field="unitCost" header="Unit Cost " className="text-center" />
                            <Column field="totalCost" header="Total Cost " className="text-center" />
                            {/* <Column body={actionBodyTemplate2} header="Actions" className="text-center"/> */}
                        </DataTable>
                        <div className="flex justify-content-between mx-1">
                            <h6 className="mt-3">Total Cost</h6>
                            <h6 className="mt-3">
                                {' '}
                                {selectedSale?.currency} {selectedSale.totalCost}
                            </h6>
                        </div>
                    </div>
                </Dialog>
            </div>
        </div>
    );
}
